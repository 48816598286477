var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("rest-table", {
    attrs: { endpoint: "labels", fields: _vm.fields, filter: _vm.filter },
    scopedSlots: _vm._u([
      {
        key: "cell(Name)",
        fn: function(data) {
          return [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "label-detail",
                    params: { id: data.item.LabelID }
                  }
                }
              },
              [_c("strong", [_vm._v(_vm._s(data.item.Name))])]
            )
          ]
        }
      },
      {
        key: "cell(ParentLabel.Name)",
        fn: function(data) {
          return [
            data.item.ParentLabel.LabelID != null
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "label-detail",
                        params: { id: data.item.ParentLabel.LabelID }
                      }
                    }
                  },
                  [_c("strong", [_vm._v(_vm._s(data.item.ParentLabel.Name))])]
                )
              : _vm._e()
          ]
        }
      },
      {
        key: "cell(Contract.Name)",
        fn: function(data) {
          return [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "contract-detail",
                    params: { id: data.item.ContractID }
                  }
                }
              },
              [_c("strong", [_vm._v(_vm._s(data.item.Contract.Name))])]
            )
          ]
        }
      },
      {
        key: "cell(RoyaltyPerc)",
        fn: function(data) {
          return [
            _c("div", { staticClass: "text-right text-nowrap" }, [
              _vm._v(
                _vm._s(_vm._f("numberFormat")(data.item.RoyaltyPerc)) + " %"
              )
            ])
          ]
        }
      },
      {
        key: "cell(RoyaltyPercStreaming)",
        fn: function(data) {
          return [
            _c("div", { staticClass: "text-right text-nowrap" }, [
              _vm._v(
                _vm._s(_vm._f("numberFormat")(data.item.RoyaltyPercStreaming)) +
                  " %"
              )
            ])
          ]
        }
      },
      {
        key: "cell(actions)",
        fn: function(data) {
          return [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "label-detail-edit",
                    params: { id: data.item.LabelID }
                  }
                }
              },
              [_c("b-icon-pencil-square")],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
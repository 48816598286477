<template>
  <rest-table
      endpoint="labels"
      :fields="fields"
      :filter="filter">

    <template v-slot:cell(Name)="data">
      <router-link :to="{ name: 'label-detail', params: { id: data.item.LabelID } }">
        <strong>{{ data.item.Name }}</strong>
      </router-link>
    </template>

    <template v-slot:cell(ParentLabel.Name)="data">
      <router-link v-if="data.item.ParentLabel.LabelID != null" :to="{ name: 'label-detail', params: { id: data.item.ParentLabel.LabelID } }">
        <strong>{{ data.item.ParentLabel.Name }}</strong>
      </router-link>
    </template>

    <template v-slot:cell(Contract.Name)="data">
      <router-link :to="{ name: 'contract-detail', params: { id: data.item.ContractID } }">
        <strong>{{ data.item.Contract.Name }}</strong>
      </router-link>
    </template>

    <template v-slot:cell(RoyaltyPerc)="data">
      <div class="text-right text-nowrap">{{ data.item.RoyaltyPerc|numberFormat }}&nbsp;%</div>
    </template>

    <template v-slot:cell(RoyaltyPercStreaming)="data">
      <div class="text-right text-nowrap">{{ data.item.RoyaltyPercStreaming|numberFormat }}&nbsp;%</div>
    </template>

    <template v-slot:cell(actions)="data">
      <router-link :to="{ name: 'label-detail-edit', params: { id: data.item.LabelID } }">
        <b-icon-pencil-square></b-icon-pencil-square>
      </router-link>
    </template>
  </rest-table>
</template>

<script>
  import RestTable from '@/components/RestTable.vue'

  export default {
    name: 'LabelList',
    components: {
      RestTable,
    },
    props: {
      filter: Object
    },
    data () {
      return {
        fields: [
          {
            key: 'idx',
            label: '#'
          },
          {
            key: 'Name',
            label: 'Naam',
            sortable: true,
          },
          {
            key: 'ParentLabel.Name',
            label: 'Parent label',
            sortable: true,
          },
          {
            key: 'Contract.Name',
            label: 'Contract naam',
            sortable: true,
          },
          {
            key: 'RoyaltyPerc',
            label: 'Royalty',
            sortable: true,
          },
          {
            key: 'RoyaltyPercStreaming',
            label: 'Royalty streaming',
            sortable: true,
          },
          {
            key: 'Status',
            label: 'Status',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Acties',
            class: 'link-icons',
          },
        ],
      }
    }
  }
</script>